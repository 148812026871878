@import "styles";

@keyframes fadeIn {
  from { opacity: 0; }
}

.postTutorialOptions {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $layer-tooltip;
  display: flex;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: absolute;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    top: -50px;
    left: -50px;
    background: $grid-gradient;
    filter: blur(10px);
    opacity: .9;
    animation: showBackdrop 400ms cubic-bezier(0, 1, .5, 1);

    @keyframes showBackdrop {
      from {
        opacity: 0;
        transform: translateY(120%);
      }
    }
  }

  .content {
    padding: 32px;
    width: min(888px, 80%);
    background: $white;
    border-radius: 16px;
    position: relative;
    animation: appear 400ms 400ms backwards;
    box-shadow: $shadow-2xl;

    @keyframes appear {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
    }

    h1 {
      @include display24Bold();

      margin: 0;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 40px;
    }

    .mainOptions {
      display: flex;
      gap: 32px;
    }

    .secondaryOptions {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 8px;

      a {
        @include paragraph16SemiBold();

        text-decoration: none;
        color: $grid-purple;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .option {
      $animationDuration: 400ms;

      text-decoration: none;
      color: $squid-ink;
      display: flex;
      flex-flow: column;
      justify-content: center;
      border-radius: 16px;
      padding: 24px;
      text-align: center;
      transition: transform $animationDuration, box-shadow $animationDuration;
      z-index: 1;
      flex: 1 1 33%;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 20px rgb(0 0 0 / 8%), 0 20px 60px rgb(0 0 0 / 10%);
        z-index: 10;
      }

      figure {
        font-size: 40px;
        margin: 0;
      }

      h2 {
        @include heading20SemiBold();

        margin: 8px 0;
      }

      p {
        @include paragraph16Regular();

        margin: 0;
      }
    }
  }

  .abortButton {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
