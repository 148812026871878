/* stylelint-disable color-no-hex */

/* Primary colors */
// Purple
$grid-purple: #5900ff;
$lupine: #7a33ff;
$lilac: #bd99ff;
$sweet-rocket: #efe5ff;
$lavender: #f8f5ff;

// Blue
$aster: #0025ff;
$gentian: #3350ff;
$scabiosa: #99a8ff;
$bluebell: #f0f1ff;
$lily: #fafbff;
$electric-cyan: #73fffe;
$chill-cyan: #008584;
$robin-egg: #00cccb;
$bubbles: #e5ffff;
$pro-blue: #3095ff;

$purple-gradient: linear-gradient(90deg, $grid-purple, $lilac);
$green-gradient: linear-gradient(90deg, #078522 0%, #1ac33e 100%);

/* Neutrals */
// Text
$squid-ink: #282b3e;
$ash: #75767d;
$fossil: #909199;

// UI
$nobel: #b2b2b2;
$concrete: #d8d9e5;
$wise-owl: #eaeaef;
$cloud: #f5f5f5;
$mist: #fafafa;

/* Semantic colors */
// Red
$pillar-box: #cc0019;
$candy-apple: #ff001f;
$morning-skies: #ffccd2;
$dusty-pink: #ffe5e9;

// XXX get feedback from design about these two colors if they will be added to core colors
$red-hover-1: #fff2f4;
$red-hover-2: #ffe6e9;

// Green
$olive-green: #009921;
$petit-pois: #00cc2c;
$green-65: #81ca81;
$green-90: #dbf0db;
$green-97: #f4faf5;

// Yellow
$fields-of-barley: #ffc200;
$warm-sand: #fff3cc;
$lemon-meringue: #fff9e5;

// Complimentary colors
$neon-lights: #eb00ff;
$pink-elephant: #ff0f91;
$sunflower: #ff9729;
$cool-ember: #ff6658;

$white: #fff;
$black: #000;

// used for focus ring outline
$focus-ring-color: $scabiosa;
// used for text selections
$selection-color: lighten($focus-ring-color, 12%);
// used to color border to complement focus
$focus-color: $grid-purple;
// used for dragzone in sheetGrid
$sheet-dragzone: rgba($scabiosa, 0.35);

$transparent: #fff0;
$menu-background: $white;
$menu-background-hover: $sweet-rocket;
$menu-background-active: $lavender;
$menu-text: $squid-ink;
$menu-text-inactive: $fossil;

// Formula syntax highlighting.
$formula-boolean: #3fa2ff;
$formula-number: #3fa2ff;
$formula-string: #21a365;

// Range highlighting colors
$range-light-blue: #39c6d9;
$range-pink: #d662d2;
$range-green: #3ccda4;
$range-blue: #1f8eb4;
$range-rose: #ed87b9;
$range-purple: #927fe6;
$range-fallback-color: #818dac;

$gutter: 16px;

/* stylelint-disable value-keyword-case */
$font-mono: "Roboto Mono", DecimaMono, Consolas, Monaco, monospace;
$font-sans: proxima-soft, sans-serif;
$font-serif: quicksand, Georgia, Cambria, "Times New Roman", Times, serif;

$font-detail: "Source Sans Pro", proxima-soft, Helvetica, "Liberation Sans",
  Arial, "Arial Nova", "Helvetica Neue", sans-serif;
$font-main: $font-sans;
$font-heading: $font-serif;
/* stylelint-enable value-keyword-case */

$font-size-small: 13px;
$font-size-medium: 15px;
$font-size-large: 18px;

$doc-width: 768px;
$head-extra-width: 119px;
$head-height: 56px;

$line-width: 40rem;
$step-x: 1.5rem;
$side-padding: 16px;

$focus-ring: 0 0 0 3px $focus-ring-color;

$designs-mobile: 498px;
$designs-tablet: 770px;
$designs-desktop: 962px;
$designs-high-res: 1668px;

// Z index layers
$layer-underneath: -1;
$layer-bottom: 0;
$layer-base: 1;
$layer-slider-label: 4;
$layer-slider: 5;
$layer-slider-thumb: 6;
$layer-click-span: 7;
$layer-control-menu: 8;
$layer-link-wrapper: 9;
$layer-document-actions: 10;
$layer-spreadsheet-panel-header: 11;
$layer-spreadsheet-panel: 12;
$layer-panel: 13;
$layer-header: 14;
$layer-workbook-editor: 15;
$layer-drawer: 30;
$layer-modal-overlay: 999;
$layer-modal: 1000;
$layer-tooltip: 1001;
$layer-toast: 1002;
$layer-dropArea: 2000;
$layer-mobileNav: 3000;
$layer-hubspot-chat: 4000;
$layer-welcomeAnimation: 4000;

$grid-ease-in: cubic-bezier(0, 1, .5, 1);

// Easing functions https://easings.net/
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);

$shadow-base: 0 1px 2px rgba($black, 0.06);
$shadow-md: 0 0 3px rgba($black, 0.03), 0 2px 7px rgba($black, 0.04),
  0 4px 6px rgba($black, 0.05);
$shadow-lg: 0 4px 4px rgba($black, 0.04), 0 10px 15px rgba($black, 0.1);
$shadow-xl: 0 0 9px rgba($black, 0.03), 0 10px 10px rgba($black, 0.04),
  0 20px 25px rgba($black, 0.1);
$shadow-2xl: 0 25px 50px rgba($black, 0.25);

$grid-gradient: linear-gradient(
  103.39deg,
  #965cff 13.11%,
  #7d82ff 43.28%,
  #3095ff 79.99%
);

$breakpoints: (
  small: 0,
  medium: $designs-mobile,
  large: $designs-tablet,
  xlarge: $designs-desktop,
);

/* stylelint-disable property-no-unknown, property-case, declaration-empty-line-before */
/* stylelint-disable-next-line */
:export {
  gridPurple: $grid-purple;
  lupine: $lupine;
  lavender: $lavender;
  sweetRocket: $sweet-rocket;

  aster: $aster;
  gentian: $gentian;
  scabiosa: $scabiosa;
  bluebell: $bluebell;
  lily: $lily;

  squidInk: $squid-ink;
  ash: $ash;
  fossil: $fossil;
  nobel: $nobel;
  concrete: $concrete;
  wiseOwl: $wise-owl;
  cloud: $cloud;
  mist: $mist;

  pillarBox: $pillar-box;
  candyApple: $candy-apple;
  morningSkies: $morning-skies;
  dustyPink: $dusty-pink;

  fieldsOfBarley: $fields-of-barley;
  warmSand: $warm-sand;
  lemonMeringue: $lemon-meringue;

  neonLights: $neon-lights;
  pinkElephant: $pink-elephant;
  sunflower: $sunflower;
  coolEmber: $cool-ember;

  white: $white;
  transparent: $transparent;

  sheetDragzone: $sheet-dragzone;

  layerUnderneath: $layer-underneath;
  layerControlMenu: $layer-control-menu;
  layerBottom: $layer-bottom;
  layerBase: $layer-base;
  layerDropArea: $layer-dropArea;
  layerPanel: $layer-panel;
  layerTooltip: $layer-tooltip;
  layerModal: $layer-modal;
  layerModalOverlay: $layer-modal-overlay;
  layerDrawer: $layer-drawer;
  layerSpreadsheetPanel: $layer-spreadsheet-panel;
  layerHeader: $layer-header;
  layerToast: $layer-toast;

  easeOutExpo: $ease-out-expo;

  designsMobile: $designs-mobile;
  designsTablet: $designs-tablet;
  designsDesktop: $designs-desktop;

  rangeLightBlue: $range-light-blue;
  rangePink: $range-pink;
  rangeGreen: $range-green;
  rangeBlue: $range-blue;
  rangeRose: $range-rose;
  rangePurple: $range-purple;
  rangeFallbackColor: $range-fallback-color;

  formulaBoolean: $formula-boolean;
  formulaNumber: $formula-number;
  formulaString: $formula-string;
}
