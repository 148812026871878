@import "styles";

$width: 4px;

.browserWarning {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: $width;
  background: $fields-of-barley;
  padding-top: 136px;

  .warningButton {
    @include resetButton();

    padding: 4px;
    border-radius: 0 8px 8px 0;
    background: $fields-of-barley;
    position: relative;
    cursor: pointer;

    &::before, &::after {
      content: "";
      position: absolute;
      left: $width;
      top: -16px;
      height: 16px;
      width: 16px;
      border-bottom-left-radius: 50%;
      background: transparent;
      box-shadow: 0 8px 0 0 $fields-of-barley;
    }

    &::after {
      transform: rotate(90deg);
      top: initial;
      bottom: -16px;
    }

    svg {
      fill: $squid-ink;
    }
  }

  .warningText {
    font-size: 14px;
    margin-left: 4px;
    padding: 8px;
    width: 200px;
    backdrop-filter: blur(2px);
    background: rgba($white, .82);
    border-radius: 8px;
    animation: inAndOut 3400ms;
    animation-fill-mode: forwards;

    @keyframes inAndOut {
      0% { opacity: 0; }
      6% { opacity: 1; }
      94% { opacity: 1; }
      100% { opacity: 0; }
    }
  }
}
