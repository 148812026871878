@import "@/grid/gridstyles.module";

$editor-margins: 40 * 2;

.marginManager {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: $designs-mobile) {
    padding: 0;
  }
}

.leftSpacer, .rightSpacer {
  // min-width 0 is required to allow the element to shrink when the viewport becomes too small to fit all elements.
  min-width: 0;
  flex-basis: 40px;
  flex-grow: 100;
  // Setting flex-shrink to 100 makes sure this element shrinks to 0 before
  // the document starts shrinking.
  flex-shrink: 100;
}

.leftSpacer {
  // The left spacer should never shrink.
  flex-shrink: 0;

  @media (max-width: $designs-mobile) {
    flex-basis: 0;
  }
}

.widthFull,
.widthWide,
.widthNormal {
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 0;
}

.widthFull {
  // prop is set so that transition works correctly in and out of this size
  flex-basis: 100vw;
}

.widthNormal {
  flex-basis: $doc-width + $layout-margin-space;
}

.widthWide {
  flex-basis: $wide-width + $layout-margin-space;
}

.isEditor {
  .widthNormal {
    flex-basis: $doc-width + $layout-margin-space + $editor-margins;
  }

  .widthWide {
    flex-basis: $wide-width + $layout-margin-space + $editor-margins;
  }
}

@media print {
  .widthNormal, .widthFull, .widthWide {
    flex-basis: 100% !important;
  }

  .leftSpacer, .rightSpacer {
    display: none;
  }
}
