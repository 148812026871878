@import "styles";

.loading {
  max-width: 674px;
  margin: 0 auto;
  animation: fadeInDelay 800ms 400ms;
  animation-fill-mode: backwards;

  @keyframes fadeInDelay {
    from { opacity: 0; }
  }

  &.embed {
    min-height: 100vh;
  }
}

.docButtonContainer {
  vertical-align: unset;
}

.docButton {
  padding: 8px 10px !important;
  margin: 0 10px 0 0 !important;
  font-family: $font-main;
  font-size: 15px;
}

.docLabel {
  display: none;

  @include breakpoint(medium) {
    display: unset;
  }
}

.saveState {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.userCard {
  max-width: 756px;
  margin: 0 auto 12px;
}

.detailsModal {
  max-height: 600px;
  min-height: 300px;
  height: auto;
}

.buttons {
  @include footerButtons();
}

.views {
  cursor: pointer;
  text-decoration: underline;
}

.documentWrapper {
  position: relative;
  padding-top: 34px;
  padding-bottom: 120px;

  @media print {
    padding-top: 0;
  }
}
