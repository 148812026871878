@import "styles";

.documentActions {
  position: fixed;
  left: 0;
  margin-top: 16px;
  padding: 0 0 0 16px;

  @media (max-width: $designs-mobile) {
    display: none;
  }

  @media print {
    display: none;
  }

  .menuItems {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  .divider {
    height: 1px;
    background: $concrete;
    width: 40px;
    margin: 8px 0;
  }
}

.documentAction {
  z-index: $layer-panel;
  position: absolute;
  top: 0;
  left: 72px;
  margin-top: 16px;
  min-width: 256px;
  background: $white;
  color: $squid-ink;
  box-shadow: $shadow-md;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 100ms linear;
  transform: scale(0);

  &.show {
    transform: scale(1);
    transition: transform 200ms $grid-ease-in;
  }

  @media (prefers-reduced-motion: reduce) {
    transition-duration: 0ms !important;
  }

  .header {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 12px 0 16px;
    background: $sweet-rocket;

    span {
      @include label12Medium();

      flex-grow: 1;
      color: $grid-purple;
    }
  }

  .action {
    padding: 16px;

    &.noPadding {
      padding: 0;
    }
  }

  .dummyContent {
    background: $nobel;
    height: 400px;
    border-radius: 12px;
  }

  .outer {
    transform-origin: top;
    overflow-y: auto;
    transition: height 200ms ease-out;

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 0ms;
    }
  }
}

.content {
  display: none;

  &.show {
    display: block;
  }
}

.documentActionsPortal {
  &.top :nth-child(2) {
    z-index: $layer-panel + 2;
  }

  &.middle :nth-child(2) {
    z-index: $layer-panel + 1;
  }
}
