@import "styles";

.draftNotice {
  &.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $sweet-rocket;
    padding: $side-padding;

    .pill {
      color: $white;
      background-color: $grid-purple;
      margin-right: 16px;
      flex-shrink: 0;
      outline: none;
    }

    p {
      @include paragraph14Regular();

      color: $grid-purple;
      margin: 0;
    }

    button {
      @include resetButton();

      align-self: flex-start;
      margin-left: 16px;
    }
  }

  &.desktop {
    margin-left: 16px;
  }

  .pill {
    @include paragraph14SemiBold();

    padding: 0 12px;
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    outline: 1px dashed currentColor;
  }
}

.tooltip {
  max-width: 180px;
}
