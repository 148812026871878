@import "styles";

.wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: min(calc(100vh - 56px - 24px), 800px);// 56px Navbar height, 12px padding below and above
  min-width: 264px;

  .scenarioButtonWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;

    &.hasScenarios {
      padding-bottom: 8px;
    }

    .loginSignupWrapper {
      @include paragraph14Regular();

      background-color: rgba($cloud, 0.5);
      padding: 8px 14px;
      border-radius: 8px;
      margin-top: 8px;

      .authenticateTextLink {
        background: none;
      }
    }
  }

  .scenarioWrapper {
    border-top: 1px solid $cloud;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    box-sizing: border-box;
    overflow: auto;
    font-size: 14px;

    .scenarioHeadingContainer {
      margin-bottom: 8px;
      width: 100%;

      .scenarioHeading {
        margin-right: 4px;

        @include paragraph14SemiBold();
      }
    }
  }

  .scenarioInput {
    margin-right: 8px;
  }

  .linkContainer {
    .linkWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding-bottom: 8px;
    }
  }

  .scenarioFormWrapper {
    @include resetForm();
  }

  .scenarioContainer {
    display: flex;
    padding-bottom: 8px;
    width: 100%;

    &:last-of-type {
      padding-bottom: 0;
    }

    $deleteButtonWidth: 32px;

    .avatar {
      min-width: 24px;
      min-height: 24px;
    }

    .scenarioButton {
      display: flex;
      align-items: center;
      width: calc(100% - #{$deleteButtonWidth});
      border-radius: 12px 8px 8px 12px;

      &:hover {
        background-color: $cloud;
        transition: background-color 240ms ease;
      }

      @include resetButton();

      .scenarioInstance {
        display: block;
        font-size: 14px;
        margin-left: 8px;
        width: 100%;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.selected {
          @include paragraph14SemiBold ();
        }
      }
    }
  }

  .linkWrapper {
    button {
      min-width: 88px;
    }
  }
}

.mobileScenariosButton {
  position: fixed;
  bottom: 24px;
  right: 24px;
  transition: transform 200ms $grid-ease-in;

  &.hide {
    transform: translateY(calc(100% + 24px));
  }

  @media (min-width: $designs-mobile) {
    display: none;
  }

  button span {
    box-shadow: $shadow-lg;
    border-radius: 50% !important;
  }
}
