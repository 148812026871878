@import "styles";

.root {
  padding-bottom: 2px;
}

.info {
  font-size: $font-size-medium;
  margin-top: 0;
}

.accordionContents {
  margin-left: 16px;
  padding-bottom: 8px;
}

.staleError {
  margin: 20px 0;
  font-size: $font-size-medium;
}
