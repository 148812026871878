@import "styles";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  pointer-events: none;
  backdrop-filter: blur(0);
  transition: backdrop-filter 800ms cubic-bezier(0, 1, .5, 1);

  &.blurIntro {
    backdrop-filter: blur(4px);
    transition: backdrop-filter 1600ms 2000ms cubic-bezier(0, 1, .5, 1);
    animation: blur 1600ms 2000ms cubic-bezier(0, 1, .5, 1);
    animation-fill-mode: both;

    @keyframes blur {
      from { backdrop-filter: blur(0); }
    }
  }

  &.blurOutro {
    backdrop-filter: blur(4px);
    transition: backdrop-filter 800ms cubic-bezier(0, 1, .5, 1);
  }
}

.tutorial {
  position: fixed;
  top: 0;
  left: 0;
  width: 344px;
  z-index: 1001;
  transition: transform 400ms;
  animation: fadeIn 400ms 2000ms cubic-bezier(0, 1, .5, 1);
  animation-fill-mode: backwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  .inner {
    display: flex;
    flex-flow: column;
    padding: 24px;
    border-radius: 16px;
    box-shadow: $shadow-2xl;
    box-sizing: border-box;
    color: $white;
    color: $squid-ink;
    background-color: $squid-ink;
    background-color: $white;
    overflow: hidden;
    position: relative;
    gap: 16px;
  }

  .title {
    @include heading24Medium();

    margin: 0;
  }

  .body {
    @include paragraph16Regular();

    strong {
      font-weight: 800;
    }
  }

  .gif {
    width: 100%;
  }

  p {
    margin: 8px 0;
  }

  var {
    background: rgba($white, 0.12);
    background: $cloud;
    padding: 0 6px;
    border-radius: 4px;
    font-weight: 600;
    font-style: normal;
  }

  .flex {
    flex-grow: 1;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 4px;
    margin-top: 24px;

    &.stacked {
      flex-flow: column;
      align-items: center;
      width: 100%;
    }

    > *:not(:last-of-type) {
      margin-right: 8px;
    }

    .fullWidth {
      width: 100%;
      text-decoration: none;

      button {
        width: 100%;
        justify-content: center;
      }
    }

    .secondary a {
      @include paragraph16SemiBold();

      padding: 8px 0;
      text-decoration: none;
      color: $grid-purple;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }

    .secondary button {
      @include paragraph16SemiBold();

      background: none;
      margin-top: 8px;

      &:hover {
        background: none;
      }
    }
  }

  .doItForMeButton {
    button {
      @include paragraph16SemiBold();

      padding: 8px 0;
      margin-top: 16px;

      &:hover {
        background: transparent;
      }
    }
  }

  .closeButton {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .finishButton {
    position: absolute;
    right: 24px;
    bottom: 24px;

    button {
      color: $squid-ink;
      background: $white;

      &:hover {
        background: $white;
      }
    }
  }

  .endTutorial {
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    color: $grid-purple;
  }

  .bottom {
    display: flex;
    align-items: center;
  }

  .rageClickText {
    @include paragraph14Regular();

    padding: 16px 24px;
    padding-bottom: 24px;
    margin: 0 -24px -24px;
    animation: fadeIn 400ms;
    text-align: center;
    background-color: $lemon-meringue;
  }

  .appear {
    animation: appear 200ms;

    @keyframes appear {
      from {
        transform: translateY(-16px);
        opacity: 0;
      }
    }
  }

  .progress {
    @include paragraph14Regular();

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .bar {
      background-color: rgba($white, .25);
      background-color: $sweet-rocket;

      > * {
        height: 8px;
        background: $white;
        background: $lupine;
        border-radius: 0 2px 0 0;
        transition: width 200ms;
      }
    }
  }
}

.bounce {
  animation: bounce 600ms ease;

  @keyframes bounce {
    0% { transform: translateY(0%); }
    30% { transform: translateY(-8%); }
    50% { transform: translateY(4%); }
    60% { transform: translateY(-4%); }
    70% { transform: translateY(0%); }
    80% { transform: translateY(-2%); }
    100% { transform: translateY(0); }
  }
}

.ripple {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-tooltip;
  pointer-events: none;

  &.on {
    $becon-color: $grid-purple;

    border: 1px solid $becon-color;
    border-radius: 50%;
    animation: ripple1 1.5s linear infinite;
    background: rgba($becon-color, .5);
    opacity: 0;

    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $becon-color;
      border-radius: 50%;
      animation: ripple2 1.5s linear infinite;
    }

    @keyframes ripple1 {
      0% {
        transform: scale(.6);
        opacity: 0;
        background: rgba($becon-color, .5);
      }

      50% {
        transform: scale(1);
        opacity: .8;
        background: rgba($becon-color, .1);
      }

      100% {
        transform: scale(1.4);
        opacity: 0;
        background: rgba($becon-color, 0);
      }
    }

    @keyframes ripple2 {
      0% {
        transform: scale(.6);
        opacity: 0;
      }

      50% {
        transform: scale(1.8);
        opacity: .8;
      }

      100% {
        transform: scale(3);
        opacity: 0;
      }
    }

    @keyframes ripple3 {
      0% {
        transform: scale(.6);
        opacity: 0;
      }

      50% {
        transform: scale(3.3);
        opacity: .8;
      }

      100% {
        transform: scale(6.6);
        opacity: 0;
      }
    }
  }
}

.wave {
  display: inline-block;
  animation: wave 200ms 2000ms 4 alternate linear;

  @keyframes wave {
    from { transform: rotate(0deg); }
    to { transform: rotate(20deg); }
  }
}

.leaveTutorialBanner {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: 1001;

  .gut {
    @include paragraph16Regular();

    background: $white;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
    display: flex;
    align-items: center;
    box-shadow: $shadow-lg;

    &.shouldAnimate {
      animation: fadeIn 400ms;
    }

    @keyframes fadeIn {
      from { opacity: 0; }
    }

    p {
      margin: 0;
    }

    button {
      @include paragraph16SemiBold();

      text-decoration: none;
      padding: 0 8px;

      &:hover, &:active {
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}
