@import "styles";

.embedWatermark {
  display: flex;
  justify-content: flex-end;

  img {
    display: block;
  }

  a {
    padding: 8px 16px;
    border-radius: 16px;
    transition: background 300ms;

    &:hover {
      background: $lavender;
    }
  }

  &.dark a:hover {
    background: rgba($white, .25);
  }

  &.light a:hover {
    background: rgba($squid-ink, .1);
  }

  &.inNotion {
    padding: 4px;

    a {
      padding: 8px;
    }
  }
}
