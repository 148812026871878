@mixin display42Bold() {
  font-family: $font-heading;
  font-weight: 700;
  font-size: 42px;
  line-height: 64px;
  margin-top: 48px;
}

@mixin display32Bold() {
  font-family: $font-heading;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-top: 32px;
}

@mixin display24Bold() {
  font-family: $font-heading;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px;
}

@mixin heading32Medium() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}

@mixin heading24Medium() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

@mixin heading20Medium() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

@mixin heading20SemiBold() {
  font-family: $font-main;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

@mixin paragraph18Regular() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

@mixin paragraph16Regular() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@mixin paragraph14Regular() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin paragraph14SemiBold() {
  font-family: $font-main;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@mixin paragraph12Regular() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin paragraph10Regular() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

@mixin paragraph16SemiBold() {
  font-family: $font-main;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@mixin label12Regular() {
  font-family: $font-main;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin label12Medium() {
  font-family: $font-main;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}

@mixin label10Medium() {
  font-family: $font-main;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
