@import "styles";

.devicePreviewButton {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  background: var(--fallback-secondary-btn-bg, $bluebell);
  margin-right: -32px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 16px;

  .label {
    @include paragraph14Regular();

    height: 32px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: 20px;
    padding-right: 8px;
    z-index: 1;
  }

  .mode {
    @include resetButton();

    width: 32px;
    height: 24px;
    flex: 0 0 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 12px;
    color: var(--fallback-secondary-btn-text, $aster);
    margin: 0 8px;

    &:hover {
      background: rgba($white, 40%);
    }
  }
}
