@import "styles";

.documentSettings {
  overflow: unset;
}

.fileName {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: -3px;
  word-break: break-all;
}

.url {
  color: $scabiosa;
}

.restoreNotice {
  z-index: $layer-panel;
  top: 62px;
  bottom: unset;
  transform: none;
  width: 300px;
  right: 20px;
  left: unset;

  @include breakpoint(xlarge) {
    right: calc(calc(calc(100% - 920px) / 2) - 20px);
  }
}

.headerPad {
  padding-top: 60px;
}

.modalButton {
  @include footerButtons();
}

.toggleComment {
  border-radius: 50%;
  padding: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  // XXX <Button> styles have more specificity and this doesn't work
  &:hover {
    background: $white;
  }

  .icon {
    color: $squid-ink;
  }

  .showComments {
    color: $squid-ink;
  }
}

.docBannerAnchor {
  margin-left: -$side-padding;
  margin-right: -$side-padding;
}

.docWrap {
  position: relative;
  // overflow: hidden;
}

.docWrap:not(.embed) {
  padding: 24px 16px;

  @media print {
    padding: 0;
  }
}

.documentActions {
  position: fixed;
  bottom: 0;
  width: 100%;
}
