@import "styles";

.inputWrapper {
  min-width: 64px;
  margin: 0 8px 0 20px;
  flex-grow: 1;
  position: relative;

  input {
    @include resetInput();

    position: absolute;
    display: block;
    color: var(--text-color);
    width: 100%;
    padding: 4px 8px;
    margin: -4px -8px;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
    height: 20px;
    border-bottom: 1px solid $transparent;
    border-radius: 4px;
    transition: background 320ms ease, border 320ms ease, color 320ms ease;

    &::placeholder {
      color: var(--text-color);
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      border-bottom: 1px solid $concrete;
      background: $white;
      color: $squid-ink;
    }

    &:disabled {
      -webkit-text-fill-color: $squid-ink;
      opacity: 1;

      @include breakpoint(medium) {
        -webkit-text-fill-color: var(--text-color);
      }
    }

    &.loading {
      cursor: progress;
    }
  }

  input,
  .hiddenTitle {
    @include paragraph16Regular();

    line-height: 22px;
  }

  .hiddenTitle {
    opacity: 0;
    z-index: $layer-underneath;
    height: 22px;
    overflow: hidden;
    pointer-events: none;
    white-space: pre-wrap;
  }

  &.isDisabled {
    span,
    input {
      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        border-bottom: 1px solid $transparent;
      }

      cursor: default;
      color: var(--text-color);
    }
  }

  @media (max-width: $designs-mobile) {
    margin: 0 8px;
  }
}

.modalButton {
  @include footerButtons();
}
