@import "styles";

$border-width: 3px;
$avatar-size: 32px;

.collaborators {
  height: $avatar-size + (2 * $border-width);
  margin: -6px 0;
  position: relative;

  .collaborator .withBorder {
    border: $border-width solid var(--nav-background, $white);
    border-radius: 50% !important;
  }

  &.hide {
    opacity: 0;
  }

  @media (max-width: $designs-desktop) {
    display: none;
  }

  .collaborator {
    position: absolute;
    top: 0;
    right: 0;
    cursor: default;
    transition: transform 400ms;
    animation: slideIn 400ms 400ms, fadeIn 200ms 400ms;
    animation-fill-mode: backwards;

    @keyframes slideIn {
      from {
        transform: translateX(0);
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
    }

    &:nth-child(1) {
      z-index: 5;
    }

    &:nth-child(2) {
      z-index: 4;
    }

    &:nth-child(3) {
      z-index: 3;
    }

    &:nth-child(4) {
      z-index: 2;
    }

    &:nth-child(5) {
      z-index: 1;
    }

    > * {
      background: var(--nav-background, $white);
    }
  }

  .collaboratorCounter {
    @include resetButton();

    height: $avatar-size + (2 * $border-width);
    width: $avatar-size + (2 * $border-width);
    background-color: $lavender;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $cloud;
    }

    .counter {
      color: $squid-ink;
      font-size: 16px;
    }
  }

  .dropdownContainer {
    position: relative;

    .dropdownWrapper {
      background-color: $white;
      color: $squid-ink;
      filter: drop-shadow(0 4px 4px rgba($black, 0.04))
        drop-shadow(0 10px 15px rgba($black, 0.1));
      border-radius: 8px;
      position: absolute;
      top: 12px;
      right: -80px;
      width: 224px;

      &::after {
        position: absolute;
        content: "";
        height: 20px;
        background: linear-gradient(
          to top,
          rgb(251 251 251 / 100%) 0%,
          rgb(251 251 251 / 0%) 100%
        );
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .title {
        @include paragraph14SemiBold();

        text-align: center;
      }

      ul {
        max-height: calc(100vh - 200px);
        overflow: auto;
        padding: 9px 16px 16px;
        margin: 0;
        list-style: none;

        .userWrapper {
          display: flex;
          align-items: center;

          .name {
            @include paragraph14SemiBold();

            width: 144px;
            padding-left: 10px;
            word-break: break-word;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
