@import "styles";

.tutorialPreviewMessage {
  position: fixed;
  height: 100%;
  width: 100%;
  background: $white;
  z-index: $layer-tooltip;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &.fadeOut {
    opacity: 0;
    transition: opacity 400ms;
  }

  .content {
    max-width: 480px;

    > * {
      animation: fadeIn 800ms backwards;
    }

    > :nth-child(2) {
      animation-delay: 800ms;
    }

    > :nth-child(3) {
      animation-delay: 2800ms;
    }

    > :nth-child(4) {
      animation-delay: 3600ms;
    }
  }

  h1 {
    @include display32Bold();
  }

  p {
    @include paragraph18Regular();
  }

  button {
    margin-top: 40px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
}
