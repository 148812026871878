@import "styles";

.animateHeight {
  transition: height 200ms $grid-ease-in;

  @media (prefers-reduced-motion: reduce) {
    transition-duration: 0ms;
  }

  &.noAnimation {
    transition: none;
  }

  .inner {
    box-sizing: border-box;
  }
}
