@mixin button() {
  @include resetButton();

  display: inline-flex;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: 0 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $wise-owl;
  background: $white;
  color: $squid-ink;
  cursor: pointer;
  font-family: $font-main;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    border-color: $cloud;
    background: $cloud;
  }

  &:focus {
    @include defaultFocus();

    border: 1px solid $wise-owl;
  }

  &:active {
    background: $wise-owl;
    border: 1px solid $wise-owl;
  }

  &[disabled],
  &:disabled {
    border-color: $wise-owl;
    color: $fossil;
    cursor: not-allowed;

    &:hover {
      background: $white;
    }
  }
}

@mixin links {
  color: $aster;
  text-decoration: underline;
  cursor: pointer;
  transition: color 240ms ease;

  &:focus {
    @include defaultFocus();
  }

  &:active,
  &:hover {
    text-decoration-thickness: 2px;
    transition: color 240ms ease;
    color: $squid-ink;
  }
}

@mixin insetBorder($color, $width: 1px) {
  box-shadow: inset 0 -#{$width} 0 0 $color, inset 0 0 0 #{$width} $color;
}

@mixin insetBottomBorder($color, $width: 1px) {
  box-shadow: inset 0 -#{$width} 0 $color;
}

@mixin inputBox() {
  -webkit-appearance: none;
  display: inline-block;
  background: $white;
  border-radius: 5px;
  border: 1px solid $fossil;
  box-shadow: inset 0 2px 0 rgba($squid-ink, 0.1);
  box-sizing: border-box;
  font-family: $font-main;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  padding: 7px 10px;
  position: relative;
  white-space: nowrap;
  width: 188px;

  &:hover {
    border-color: $squid-ink;
  }

  &:focus {
    outline: none;
    border-color: $focus-color;
    box-shadow: inset 0 2px 0 rgba($squid-ink, 0.1), $focus-ring;
  }

  &:disabled {
    border-color: $wise-owl !important;
    background-color: $mist !important;
    box-shadow: inset 0 1px 0 $mist !important;
    cursor: not-allowed !important;
    color: var(--color-50);
    opacity: 1;
  }

  &::placeholder {
    color: var(--color-50);
    opacity: 1;
  }
}

@mixin touchUI() {
  -webkit-touch-callout: none;
  touch-callout: none; /* stylelint-disable-line */
  -webkit-tap-highlight-color: rgba($squid-ink, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  touch-action: none;
}

@mixin defaultFocus {
  outline: none;

  &[data-focus-visible-added] {
    border-radius: 4px;
    box-shadow: $focus-ring;
  }
}

// XXX Decide on focus to use for everything
@mixin newDefaultFocus {
  outline: none;

  &[data-focus-visible-added] {
    @include insetBorder($grid-purple, 1px);
  }
}

@mixin customKeyboardFocus {
  &[data-focus-visible-added] {
    border-color: none;
    box-shadow: none;
    @content;
  }
}

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint: map-get($breakpoints, $breakpoint);

    @if $breakpoint == 0 {
      @content;
    } @else {
      @media screen and (min-width: $breakpoint) {
        @content;
      }
    }
  } @else {
    @warn "Unsupported breakpoint #{$breakpoint}.";
  }
}

@mixin visiblyHidden {
  display: inline-block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  z-index: $layer-underneath;
  left: -9999px;
}

@mixin editScreenSize($display: inline-block) {
  display: none !important;

  @include breakpoint(large) {
    display: $display !important;
  }
}

@mixin colLayout {
  width: calc(100% - #{$gutter});
  flex-grow: 0;
  flex-shrink: 0;

  &-1\/1 {
    width: calc(100% - #{$gutter});
  }

  &-1\/2,
  &-2\/4 {
    width: calc(50% - #{$gutter});
  }

  &-1\/4 {
    width: calc(25% - #{$gutter});
  }

  &-3\/4 {
    width: calc(75% - #{$gutter});
  }

  &-1\/3 {
    width: calc(33.33% - #{$gutter});
  }

  &-2\/3 {
    width: calc(66.66% - #{$gutter});
  }

  &-1\/5 {
    width: calc(20% - #{$gutter});
  }

  &-1\/6 {
    width: calc(16.664% - #{$gutter});
  }
}

@mixin tooltip {
  z-index: $layer-tooltip;
  padding: 4px 8px;
  background: $squid-ink;
  border-radius: 4px;
  color: $white;
  font-family: $font-sans;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: normal;
  line-height: 125%;
}

@mixin innerGap($gap: 1rem) {
  > * {
    margin-right: $gap;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin spaceVertically($gap: 1rem) {
  > * {
    margin-bottom: $gap;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin footerButtons($gap: 1rem) {
  @include innerGap();

  display: flex;
  justify-content: flex-end;
}
