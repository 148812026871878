@mixin resetAll {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  margin: 0;
  border: none;
  padding: 0;
}

@mixin resetButton() {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  font-family: $font-main;
}

@mixin resetInput() {
  @include resetAll();

  border-radius: 0;
  background: none;
  appearance: none;
  vertical-align: baseline;

  &:focus {
    border: none;
    outline: none;
  }
}

@mixin resetForm() {
  display: inherit;
}