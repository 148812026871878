@import "styles";

.container {
  margin-bottom: 16px;

  .alert {
    .multipleErrors {
      font-weight: 600;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .errors {
    margin-left: -10px;
    margin-top: -10px;
  }
}