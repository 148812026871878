@import "styles";

.documentMenu {
  height: 32px;
}

.hideOnSmallScreens {
  @include editScreenSize(inline-flex);
}

.exportToPDFUpgradeModal {
  h1 {
    @include display24Bold();
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
  }

  .closeModal {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.button {
  color: var(--text-color, $squid-ink);

  svg,
  path {
    fill: var(--text-color, $squid-ink);
  }

  &:hover {
    background: rgba($fossil, 0.3) !important;
  }
}