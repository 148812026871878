@import "styles";

.saveStatus {
  position: absolute;
  top: 56px;
  left: 56px;
  height: 15px;
  width: max-content;
  background-color: var(--nav-background);
  backdrop-filter: blur(2px);
  padding: 0 16px 4px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0;
  pointer-events: none;

  .message {
    margin-top: -15px;
  }

  span {
    @include paragraph14Regular();

    color: var(--text-color, $ash);
    margin: 0;
  }

  button {
    @include resetButton();

    color: $grid-purple;
    margin-left: 4px;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    &:nth-of-type(2) {
      color: $pillar-box;
    }
  }
}
