@import "styles";

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 24px;
  justify-content: center;

  .suggestion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    flex-grow: 1;
    height: 204px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid $wise-owl;
    transition: box-shadow 200ms ease;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow: $shadow-md;
    }

    &:focus {
      box-shadow: $shadow-md;
      outline: none;

      .title {
        background-color: $cloud;
      }
    }

    .chart {
      width: calc(100% - 24px);
      overflow: hidden;
      box-sizing: border-box;
      margin: 12px 0;
    }

    .title {
      @include paragraph16SemiBold();

      color: $squid-ink;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 12px 12px;
      background-color: $mist;
      text-align: center;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.horizontalOverlay {
      &::after {
        position: absolute;
        width: 100%;
        height: 24px;
        content: "";
        bottom: 44px;
        background: linear-gradient($transparent, var(--background-color));
      }
    }

    &.verticalOverlay {
      &::before {
        position: absolute;
        height: calc(100% - 44px);
        width: 24px;
        content: "";
        right: 0;
        background: linear-gradient($transparent, var(--background-color));
        z-index: $layer-base;
      }
    }
  }

  .subText {
    @include label12Regular();

    width: 100%;
    text-align: right;
    padding: 16px 0;
  }
}
