@import "styles";

$column: 101px;
$gutter: 15px;

.root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  text-align: center;
  background: var(--background-color);
}

.doc {
  width: calc(#{$column * 6 + $gutter * 5} + #{$step-x + $step-x});
  transform-origin: top center;
  display: inline-block;
  text-align: left;
  margin-top: $gutter;
  transition-property: margin-top;
  transition-duration: 0.1s;
}
