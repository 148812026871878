@import "styles";

.editToggle {
  @include resetButton();

  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--fallback-primary-btn-bg, linear-gradient(90deg, $aster, $scabiosa));
  color: var(--fallback-primary-btn-text, $white);
  padding: 0 12px;
  min-width: 78px;

  &:hover {
    background: var(--fallback-primary-btn-bg-hover, linear-gradient(90deg, $aster, $scabiosa));
  }

  svg {
    color: var(--fallback-primary-btn-text, $white);
  }

  &:focus[data-focus-visible-added] {
    outline: none;
    box-shadow: 0 0 0 2px $grid-purple;
  }
}

.modalButton {
  @include footerButtons();
}
