@import "styles";

.root {
  display: flex;
  align-items: center;
  border: 1px solid $fields-of-barley;
  background: $lemon-meringue;
  margin-bottom: 2em;
  padding: 12px;
  line-height: 1.3;
  position: relative;
  border-radius: 4px;

  .icon {
    color: $squid-ink;
    font-size: 20px !important;
    margin-right: 12px;
  }
}
