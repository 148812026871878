@import "styles";

.actionButton {
  @include resetButton();

  display: flex;
  align-items: center;
  gap: 16px;
  transition: width 0ms 200ms, overflow 0ms 200ms;
  outline: none;

  &.useTheme {
    color: var(--fallback-secondary-btn-text, $squid-ink);

    &.isSelected .icon {
      background: var(--fallback-secondary-btn-bg, $sweet-rocket);
      color: var(--fallback-secondary-btn-text, $grid-purple);
    }

    &:hover:not(.isSelected) {
      &:active .icon {
        background: var(--fallback-secondary-btn-bg, $sweet-rocket);
        color: var(--fallback-secondary-btn-text, $grid-purple);
      }

      .icon {
        background: var(--fallback-secondary-btn-bg, $sweet-rocket);
        color: var(--fallback-secondary-btn-text, $grid-purple);
      }
    }
  }

  &.isSelected .icon {
    background: $sweet-rocket;
    color: $grid-purple;
  }

  &.hideLabel {
    overflow: hidden;
    width: 40px;

    span {
      opacity: 0;
      height: 0;
    }
  }

  &:hover:not(.isSelected):not(.useTheme) {
    color: $grid-purple;

    &:active .icon {
      background-color: $lilac;
    }

    .icon {
      background: $sweet-rocket;
    }
  }

  .icon {
    border-radius: 8px;
    padding: 8px;
    transition: background 200ms, color 200ms;
  }

  span {
    @include paragraph16Regular();
  }

  &.primary {
    color: $grid-purple;
  }

  &:disabled {
    opacity: .5;
    cursor: initial;
  }
}
