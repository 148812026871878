@import "styles";

.helpCenter {
  .popover {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 340px;
    transition: width 200ms $grid-ease-in;

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 0ms;
    }

    &.wider {
      width: 512px;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: 16px;

    &.swipeLeft {
      animation: swipeLeft 200ms ease-in;

      @keyframes swipeLeft {
        to { transform: translateX(-100%); }
      }

      @media (prefers-reduced-motion: reduce) {
        animation-duration: 0ms;
      }
    }
  }

  p {
    @include paragraph16Regular();

    font-weight: 600;
    text-align: center;

    &.light {
      font-weight: 400;
    }
  }

  h4 {
    @include display24Bold();

    margin: 0;
    text-align: center;
    padding-top: 24px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    display: flex;
    flex-flow: column;

    button {
      @include resetButton();

      display: flex;
      align-items: center;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      box-sizing: border-box;
      transition: background 200ms;

      &:hover {
        background: $cloud;
      }
    }

    span {
      @include paragraph16Regular();

      display: block;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 16px;
      text-align: left;
    }
  }

  .iframe {
    display: flex;
    flex-flow: column;
    padding-top: 16px;
    box-sizing: border-box;
    flex-grow: 1;
    min-height: calc(100vh - 300px);

    iframe {
      border: 0;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      box-sizing: border-box;
      flex-grow: 1;
    }
  }

  .menu {
    display: flex;
    flex-flow: column;
    height: inherit;
  }

  .searchInput {
    margin-bottom: 24px;
  }

  .instruction {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    animation: appear 200ms;
    animation-fill-mode: forwards;

    @keyframes appear {
      from { opacity: 0; }
    }
  }

  .actions {
    display: flex;
    gap: 8px;
    padding: 8px 0;
    z-index: 1;
    background-color: $cloud;
    justify-content: space-between;

    svg, path {
      color: $squid-ink;
    }
  }

  .backButton {
    margin-left: 8px;
    margin-top: 8px;
  }

  .allHelpButton {
    display: flex;
    justify-content: center;
    margin: 24px;

    button {
      padding: 18px 24px;
    }
  }

  .bottomButtons {
    display: flex;
    justify-content: space-around;
    padding: 8px 0;
  }

  .flex {
    flex-grow: 1;
  }

  .skeleton {
    padding: 16px;

    section {
      margin-bottom: 16px;
    }
  }

  .backToHomeButton {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 32px;
    display: flex;
    justify-content: center;
    animation: flyIn 400ms 4s;
    animation-fill-mode: backwards;

    > * {
      box-shadow: $shadow-2xl;
    }

    @keyframes flyIn {
      from { transform: translateY(100px); }
    }

    @media (prefers-reduced-motion: reduce) {
      animation-duration: 0ms;
    }
  }
}
