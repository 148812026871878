@import "styles";

.accordion {
  transition: height 200ms ease-in-out;

  &.disableAnimation {
    transition: none;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  .wrapper {
    width: 100%;
    font-size: 14px;

    .root {
      cursor: pointer;
      min-width: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 20px;
      box-sizing: border-box;
      background: $cloud;
      transition: background-color 0.1s ease-in-out;
      color: $squid-ink;
      min-height: 32px;
      border-radius: 8px;
      width: calc(100% - 24px);
      margin-left: 12px;

      &:focus {
        outline: none;
      }

      &[data-focus-visible-added] {
        background-color: $wise-owl;
      }

      // Align arrow icon to title when using subtitle.
      &.withSubtitle {
        align-items: flex-start;

        .icon,
        .leftIcon,
        .rightIcon {
          margin-top: 24px;
          flex-shrink: 0;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .leftIcon {
      margin-left: 16px;
      margin-right: 8px;
    }

    .icon {
      margin-left: 12px;
      margin-right: 8px;
      user-select: none;

      &.reversed {
        margin-left: 4px;
      }

      &.disabled {
        color: $fossil;
        cursor: not-allowed;
      }
    }

    .content {
      padding-top: 16px;
      padding-bottom: 10px;
      box-sizing: border-box;
    }

    .titleGroup {
      margin: 24px 0 24px 8px;
      display: inline-flex;
      min-width: 0;
      flex-direction: column;
      flex-grow: 1;

      h4 {
        margin: 0 0 2px;
      }

      label {
        font-size: 14px;
        line-height: 20px;
        color: $ash;
        margin: 0;
      }
    }
  }

  .title {
    @include label12Medium();
  }

  // Light theme. Borders removed and background shading only used for keyboard navigation.
  &.lightTheme {
    .root {
      min-height: 40px;
      border: 0;
      background: $white;
      border-radius: initial;
      width: 100%;
      margin-left: 0;
    }

    .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      text-transform: none;

      @include paragraph16Regular();

      letter-spacing: initial;
    }

    .content {
      padding-top: 8px;
      padding-bottom: 10px;
    }

    .icon {
      margin-left: 16px;
    }

    &:not(:first-child) {
      border-top: 1px solid $wise-owl;
    }
  }

  &:not(.lightTheme) {
    .wrapper {
      .content {
        > div:last-child {
          padding-bottom: 24px;
        }
      }
    }

    margin-top: 8px;
  }

  &.minPadding {
    .wrapper {
      .root {
        width: initial;
        margin: 0;
      }

      .content {
        padding-top: 8px;
        padding-bottom: 8px;

        > div:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
