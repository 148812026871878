@import "styles";

.documentStatistics {
  padding: 0 0 24px;
  font-size: 16px;
  line-height: 22px;
}

.totalViews {
  margin: 32px 0 24px;
}

.noData {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($white, .9);
  text-align: center;
  padding: 0 16px;
  box-sizing: border-box;
}

.geoBanner {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin: 32px 0 0;
}

.promoBanner {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: -16px;
  background: $mist;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include breakpoint(medium) {
    margin-top: -24px;
    font-size: 18px;
    padding: 32px 40px;
  }

  h1 {
    @include display24Bold();

    margin: 0;
  }

  p {
    @include paragraph16Regular();
  }

  a {
    text-decoration: underline;
  }
}

.promoMobileFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
