@import "styles";

$top-padding: 8px;
$side-padding: 16px;

.documentNav {
  position: sticky;
  top: 0;
  height: 64px;
  z-index: $layer-panel;
  display: flex;
  justify-content: space-between;
  // Here to make sure users can click between the navbars.
  pointer-events: none;
  width: calc(100vw - 20px);

  .navWrap > * {
    pointer-events: auto;
  }

  @media print {
    display: none;
  }
}

.navWrap.wrapRight > * {
  margin-left: auto !important;
}

.navWrap.wrapRight .navbar {
  margin-right: 0;
}

.documentNav.canEdit:not(.isMobile) > .navWrap {
  &.wrapRight,
  &.wrapLeft {
    flex-basis: calc(50% - 54px);
  }
}

.navbar {
  background-color: var(--nav-background);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  min-height: 48px;
  border-radius: 8px;
  margin: 8px;
  box-sizing: border-box;
  color: var(--text-color, $squid-ink);
  animation: fadeIn 200ms;
  width: fit-content;

  &:empty {
    display: none;
  }

  .isMobile & {
    background-color: transparent;
    padding: 0;
    margin: 4px 0 0;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
  }

  &.left {
    // Center GRID logo above doc actions.
    padding-left: 12px;

    button {
      flex-shrink: 0;
    }
  }

  &.middle {
    padding: 4px;
  }

  &.right {
    gap: 16px;

    .userMenuWrap {
      @media (max-width: 600px) {
        display: none;
      }
    }

    .loginHref {
      text-decoration: none;
    }
  }

  .accountActions {
    button svg path {
      fill: var(--text-color, $squid-ink);
    }

    display: flex;
    border-radius: 16px;
    // XXX: --share-actions-background-color does not exist!
    // background-color: var(--share-actions-background-color, $lavender);
    background-color: var(--nav-user-background, $lavender);
  }
}

.documentNav.isMobile {
  height: $head-height;
  background-color: $white;
  padding-left: 8px;
  gap: 8px;
  justify-content: space-between;

  &::before {
    content: "";
    position: absolute;
    background-color: $white;
    width: 100vw;
    left: 0;
    height: 100%;
    box-shadow: $shadow-md;
    z-index: -1;
  }

  .wrapLeft {
    flex-basis: 100%;

    .navbar {
      width: 100%;
    }
  }
}

.undoActionPortal {
  margin-left: 8px;
}

.documentMenu {
  margin-left: 4px;
}
