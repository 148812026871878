@import "styles";

.container {
  width: 100%;
  border: 2px solid;
  box-sizing: border-box;

  &.error {
    border-color: $candy-apple;

    .header {
      background-color: $morning-skies;
      border-bottom: 2px solid $candy-apple;
      color: $candy-apple;
    }
  }

  &.warning {
    border-color: $fields-of-barley;

    .header {
      background-color: $warm-sand;
      border-bottom: 2px solid $fields-of-barley;
    }
  }

  &.notice {
    border-color: $wise-owl;

    .header {
      background-color: $mist;
      border-bottom: 2px solid $wise-owl;
    }
  }

  .header {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 12px;

    .label {
      padding-left: 12px;
      font-size: $font-size-medium;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .body {
    padding: 16px 20px 0 16px;
    font-size: $font-size-medium;

    p:first-child {
      margin-top: 0;
    }
  }
}